import Vue from "vue";
import VueRouter from "vue-router";

import Shoye from '@/pages/shoye'
Vue.use(VueRouter);
const router = new VueRouter({
        mode: 'history',
        routes: [{
                path: '*',
                redirect: '/'
            }, {
                path: '/home',
                name: 'Shoye',
                component: Shoye,

            }, {
                path: '/about',
                name: 'Guanyu',
                component: () =>
                    import ('@/pages/guanyu'),

            }, {
                path: '/zs',
                name: 'TeSt',
                component: () =>
                    import ('@/pages/test')
            }, {
                path: '/tg',
                name: 'Tg',
                component: () =>
                    import ('@/pages/tg')
            },
            {
                path: '/',
                name: 'Yh',
                component: () =>
                    import ('@/pages/yh')
            }, {
                path: '/jz',
                name: 'Jz',
                component: () =>
                    import ('@/pages/jz')
            }, {
                path: '/sj',
                name: 'Sj',
                component: () =>
                    import ('@/pages/sj')
            },
            {
                path: '/wj',
                name: 'Wj',
                component: () =>
                    import ('@/pages/wj')
            }, {
                path: '/al',
                name: 'al',
                component: () =>
                    import ('@/pages/al')
            },
            {
                path: '/fw',
                name: 'fw',
                component: () =>
                    import ('@/pages/fw')
            },
            {
                path: '/pay',
                name: 'Pay',
                component: () =>
                    import ('@/pages/pay')
            },
            {
                path: '/dh',
                name: 'Dh',
                component: () =>
                    import ('@/pages/dh')
            },
            {
                path: '/gydh',
                name: 'Gydh',
                component: () =>
                    import ('@/pages/gydh')
            },
            {
                path: '/rc',
                name: 'RcWz',
                component: () =>
                    import ('@/pages/rc')
            },
            {
                path: '/tt',
                name: 'TeXt',
                component: () =>
                    import ('@/pages/tt')
            },


        ],
        // 路由跳转后，位置为顶部
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return
            } else {
                return { x: 0, y: 0 }
            }

        }
    })
    // router.beforeEach((to, from, next) => {
    //     if (to.path === '/search') {
    //         to.meta.keepAlive = true;
    //     } else if (to.path === '/details' && from.path === '/search') {
    //         from.meta.keepAlive = true;
    //     } else {
    //         to.meta.keepAlive = false;
    //     }
    //     next()
    // })
export default router;